import React from "react";
import { Link } from "gatsby";
import { Typography, withStyles } from "@material-ui/core";

const NewsCollectionItem = ({ slugLink, title, date, excerpt, classes }) => {
	return (
		<div style={{ paddingTop: 16, paddingBottom: 16 }}>
			<Typography variant="h5">
				<Link className={classes.titleLink} to={slugLink}>
					{title}
				</Link>
			</Typography>

			<Typography className={classes.dateline}>{date}</Typography>
			<Typography
				className={classes.postBody}
				variant="body1"
				dangerouslySetInnerHTML={{
					__html: linkToLocalSlug(excerpt, slugLink)
				}}
			/>
		</div>
	);
};

const styles = ({ palette, spacing }) => {
	return {
		titleLink: {
			"&, &:hover, &:focus": {
				color: palette.text.primary,
				textDecoration: "none"
			}
		},
		dateline: {
			color: palette.text.secondary
		},
		postBody: {
			marginTop: spacing.unit * 2
		}
	};
};

const linkToLocalSlug = (str, slugLink) =>
	str.replace(/href="(.*?)"/, () => `href="${slugLink}"`);

export default withStyles(styles)(NewsCollectionItem);
