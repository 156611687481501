import React from "react";
import { graphql } from "gatsby";
import News from "../news";

const NewsPage = ({
	data: {
		allWordpressPost: { group }
	},
	...props
}) => {
	const news = group.map(({ dateGroup, edges }) => ({
		date: dateGroup,
		posts: edges.map(({ node }) => node)
	}));

	return <News news={news} {...props} />;
};

export default NewsPage;

export const pageQuery = graphql`
	query NewsQuery {
		allWordpressPost(sort: { fields: [date], order: DESC }, limit: 20) {
			group(field: dateGroup) {
				dateGroup: fieldValue
				edges {
					node {
						id
						dateGroup
						slugYear: date(formatString: "YYYY")
						slugMonth: date(formatString: "MM")
						date(formatString: "MMMM Do, YYYY")
						isoDate: date
						slug
						title
						excerpt
					}
				}
			}
		}
	}
`;
