import React from "react";
import { Link } from "gatsby";
import {
	Grid,
	Paper,
	Divider,
	List,
	ListSubheader,
	ListItem,
	ListItemText,
	withStyles
} from "@material-ui/core";
import { Helmet } from "react-helmet";
import NewsCollectionItem from "./article";

const filterRecent = require("./massage-wordpress-posts").filter(6);

const News = ({ news, classes }) => {
	news = filterRecent(news);

	const itemsNav = (
		<List dense disablePadding>
			{news.map(({ date, posts }) => (
				<React.Fragment key={date}>
					<ListSubheader disableSticky>{date}</ListSubheader>
					{posts.map(({ title, slug, slugYear, slugMonth }) => (
						<ListItem
							component={Link}
							button
							to={`/news/${slugYear}/${slugMonth}/${slug}`}
							key={title}
							title={title}
						>
							<ListItemText
								primary={
									<div
										style={{
											whiteSpace: "nowrap",
											overflow: "hidden",
											textOverflow: "ellipsis"
										}}
									>
										{title}
									</div>
								}
							/>
						</ListItem>
					))}
				</React.Fragment>
			))}
		</List>
	);

	const itemsBody = (
		<div>
			{news.map(({ posts, date }) => {
				return (
					<div key={date}>
						<Paper className={classes.block}>
							{posts.map(
								(
									{ id, title, slugYear, slugMonth, slug, date, excerpt },
									i
								) => {
									const slugLink = `/news/${slugYear}/${slugMonth}/${slug}`;
									return (
										<React.Fragment key={id}>
											<NewsCollectionItem
												{...{ slugLink, title, date, excerpt }}
											/>
											{i + 1 < posts.length ? <Divider /> : null}
										</React.Fragment>
									);
								}
							)}
						</Paper>
					</div>
				);
			})}
		</div>
	);

	return (
		<>
			<Helmet>
				<title>News</title>
			</Helmet>

			<div className={`container ${classes.rootContainer}`}>
				<Grid container spacing={16} direction="row-reverse">
					<Grid item md={3} zeroMinWidth>
						{itemsNav}
					</Grid>
					<Grid item md={9}>
						{itemsBody}
					</Grid>
				</Grid>
			</div>
		</>
	);
};

const styles = ({ spacing, palette }) => ({
	rootContainer: {
		paddingBottom: spacing.unit * 4
	},
	block: {
		paddingLeft: spacing.unit * 2,
		paddingRight: spacing.unit * 2,
		marginBottom: spacing.unit * 4
	},
	groupHeader: {
		paddingLeft: spacing.unit * 2,
		paddingBottom: spacing.unit,
		color: palette.text.secondary
	}
});

export default withStyles(styles)(News);
