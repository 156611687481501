/* eslint-disable import/no-commonjs */

const moment = require("moment");
const he = require("he");

exports.default = (dateGroupFormatter = "MMMM YYYY") => ({ entities }) =>
	entities.map(({ title, ...e }) => ({
		dateGroup: moment(e.date).format(dateGroupFormatter),
		title: title ? he.decode(title) : null,
		...e
	}));

exports.filter = (n = 6, unit = "months") => news => {
	const now = moment(new Date());
	const cutoff = now.subtract(n, unit);

	return news.filter(n => {
		const dates = n.posts.map(p => moment(p.isoDate));
		const date = moment.min(dates);
		return date.isAfter(cutoff);
	});
};
